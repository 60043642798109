// menu

header{
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;
	background: #16A0C4;
}

.content_margin_top{
	height: 100px; // this set the menu -> content margin
}

.menuItemLogo{
	max-width: 160px;
	cursor: pointer;

	img{
		width: 160px;
		max-width: 160px;
	}
}

.menu_modules_right{
	display:flex;
	flex-direction:row;
	align-items: center;
	justify-content: center;
}

.lang_switcher{
	display:flex;
	flex-direction:row;
	align-items: center;
	justify-content: center;
}

// hamburger

.mobile_menu, .mobile_menu_open{
	width:36px;
	height:36px;
	cursor: pointer;
	background: url('../img/structure/menu_open.svg') no-repeat center center;
	background-size: 36px 36px;
	margin-left:20px;
}

.mobile_menu_open{
	background: url('../img/structure/menu_close.svg') no-repeat center center;
	background-size: 36px 36px;
}



.menu_container{
	width:100%;
}

.menu_modules{
	height: 70px;
	display:flex;
	flex-direction:row;
	align-items: center;
	justify-content: space-between;
	max-width: calc(#{$content_max_width} + var(--gutter) * 2);
	margin:0 auto;

	width:100%;
	padding-left:var(--gutter);
	padding-right:var(--gutter);
}

.mobile_menu{
	display:block;
}

.menu_bar{
	width:100%;
	height: 0px;
	position: fixed;
	background: #16A0C4;
	overflow: hidden;

}

.menuItem, .langSwitchLink, .lang_switcher span, .menu_items{
	font-family: 'Fjalla One', sans-serif;
	color:#fff;
	font-size:20px;
	line-height: 24px;
	text-transform: uppercase;
	padding: 10px 27px;
	cursor:pointer;
	text-align: center;
}

.lang_switcher{

	span{
		padding: 0px 0px;
	}

	.langSwitchLink{
		padding: 10px 5px;
		cursor:pointer;

		a{
			color:#fff;
			text-decoration: none;					
		}
	}

}

// active
.langSwitchLink.activeLangLink a{
	text-decoration: underline;
}

.content_margin_top{
	height: 70px; // this set the menu -> content margin
}


@media screen and (min-width: 992px){
	.menu_container{
		height:100px;
	}

	.menu_modules{
		height:100px;
	}

	.content_margin_top{
		height: 100px; // this set the menu -> content margin
	}
}

// menu_agenda_name
.menu_agenda_name{
	opacity: 0;
	transition: all 0.3s;
	display: block;

	p{
		font-family: $header_font_family;
		font-size: 24px;
		line-height: 30px;
		text-transform: uppercase;
		margin:0;
		padding: 0px;
		color:#fff;
	}
}

.menu_agenda_name_show{
	opacity: 1;
}

@media screen and (max-width: 991px){
	.menu_agenda_name{
		display: none;
	}	
	
	.menu_agenda_name_show{
		opacity: 0;
	}	
}

@media screen and (max-width: 767px){
	.menu_modules .menuItemLogo{
		max-width: 120px;
		cursor: pointer;

		img{
			width: 120px;
			max-width: 120px;
		}
	}
}
