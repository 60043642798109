// _hero


.hero{
	position: relative;

	.video_container{
		position: relative;
		overflow: hidden;
		width:100%;
		height:54vh;
		min-height:400px;

		.video_inner{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			display:flex;
			flex-direction:column;
			align-items:center;
			justify-content: center;

			#hero_video{
				min-width: 100%;
				width: 100%;
				margin-top: -7%;
			}
		}
	}

	.headers_container{
		position: absolute;
		top:0;
		bottom:0;
		left:0;
		right:0;
		z-index: 2;

		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content: center;

		.preheader, .header{
			display:block;
			font-family: $header_font_family;
			text-align: center;
			color:#fff;
			font-weight: 400;
		}
		
		.preheader{
			font-size:24px;
			line-height: 30px;
			text-transform: uppercase;
			margin-bottom: 24px;
			padding:0 20px;
		}
		h1{
			margin:0;
			padding:0;
		}

		.header{
			font-size:64px;
			line-height: 74px;
			text-transform: uppercase;
			padding: 0 var(--gutter);
		}

		.hero_button{
			background: $color_dark_blue;
			font-family: $header_font_family;
			text-align: center;
			color:#fff;
			font-size:24px;
			line-height: 30px;
			text-transform: uppercase;
			margin-top: 6.5%;
			padding: 36px 50px 32px 50px;
			cursor: pointer;
		}


	}

	.scroll_icon{
		position: absolute;
		bottom: 20%;
		left:50%;
		z-index: 2;
	}

}

@media screen and (max-width: 1250px){
	.hero .video_container .video_inner #hero_video {
		min-width: 100%;
		width: 120%;
		margin-top: 0%;
	}
}

// ---------------------
// MOBILE BREAK < 992
// ---------------------
@media screen and (max-width: 991px){
	.hero{
		.headers_container{

			.header{
				font-size:50px;
				line-height: 58px;
			}

		}
	}

	.hero .video_container .video_inner #hero_video{
		width: auto;
		margin-top: 0%;
		min-height: 100%;
	}


}

@media screen and (max-width: 767px){
	.hero{
		.headers_container{

			.header{
				font-size:40px;
				line-height: 50px;
			}

		}
	}
}

// ---------------------
// SMALL MOBILE < 500
// ---------------------
@media screen and (max-width: 499px){
	.hero{
		.headers_container{

			.header{
				font-size:30px;
				line-height: 38px;
			}

		}

	}
}


/* .video_overlay{
	position: absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	background: rgba(22, 160,196, 0.2);
} */
