// Cookie bar


.CookieConsent{
	background-color: rgb(235, 246, 250) !important;
	color: $text_color_standard !important;
	font-family: $standard_font_family;
	font-size: 14px;
	line-height: 20px;

	button{
		color: #ffffff !important;
		background-color: $color_dark_blue !important;
		font-family: $standard_font_family;
		font-size: 16px;
		line-height: 20px;
	}
}