// _footer

.footer{
	color:#fff;

	.info_container{
		background: #{$text_color_standard};
	}

	.partners_container{
		background-color: #2d2223;
	}
}

.footer .grid{
	display:flex;
	flex-direction:row;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: nowrap;
	width:100%;
	max-width: calc(#{$content_max_width} + var(--gutter) * 2);
	margin:0 auto;
	padding: 100px var(--gutter);

	.col{
		margin-right:66px; 

		ul, li{
			list-style:none;
			margin:0;
			padding:0;
		}

		h5{
			font-family: $header_font_family;
			color:#fff;
			font-weight:400;
			text-decoration: none;
			font-size: 20px;
			line-height: 31px;
			text-transform: uppercase;
		}
		
		a, a:hover, a:visited, a:active, span{
			font-family: $standard_font_family;
			color:#fff;
			font-weight:400;
			text-decoration: none;
			font-size: 16px;
			line-height: 27.5px;
		}
		
		a{

		}

		span{
			white-space: nowrap;
		}

		// partners
		.logo_grid{
			display:flex;
			flex-direction:row;
			align-items: flex-start;
			justify-content: flex-start;
			
			.logo_col{
				margin: 0px 30px 0 0;

				img{
					max-height:80px;
					display:block;
				}	
			}
		}
	}

	.col5, .col6{
		// flex-basis:100%;

		.agenda_download{
			max-width:300px;
		}

		a{
			position: relative;
		}

		a:after{
			content: '';
			width: 17px;
			height: 17px;
			background: url('../img/structure/link_blank.svg') no-repeat center center;
			position: absolute;
			background-size: contain;
			margin: 4px 0 0 10px;
		}

		p{
			color:#fff;
			margin-top:0px;
		}
	}

	.col6{
		.agenda_download{
			max-width:200px;
		}
	}

	.footer_powered_by{
		color:#fff;
		margin:0 0 10px 0;
		font-size: 14px;
		line-height: 18px;

	}
}

.footer .partners_grid{
	padding: calc(var(--gutter) * 2) var(--gutter);

	#rise_logo{
		width:62px;
	}

	img{
		max-width:520px;
		width: 80%;
	}
}

// ---------------------
// HALF LARGE - less gutter
// ---------------------
@media screen and (max-width: 1200px){
	
	.footer{

		.grid{ 
			flex-wrap: wrap;
			max-width: calc(760px + var(--gutter) * 2);

			.col{
				margin-right: 50px;
				margin-right: 0;
				flex-basis:33.33333%;

			}
			
			
			.col5{ // old agenda
				padding-right: var(--gutter);
			}

			.col4, .col5, .col6{
				margin-top: var(--gutter);
			}
		}

		.partners_grid{
			flex-wrap: nowrap;
			
			.col4{
				flex-basis: auto;
			}
		}
	}
}

// ---------------------
// MOBILE BREAK < 992
// ---------------------
@media screen and (max-width: 991px){
	.footer{

		.info_container, .partners_container{
			padding: var(--block-margin-top) var(--gutter) var(--block-margin-bottom) var(--gutter);
		}

		.grid{ 
			flex-direction: row;
			align-items: flex-start;
			justify-content: flex-start;
			flex-wrap:wrap;
			padding:0;
			margin:0 auto;
			max-width: $mobile_one_col_max_width;
			max-width: 400px;

			.col{
				margin-right: 0px;
				flex-basis:100%;
				// min-width:200px;
				margin-top: var(--gutter_mobile_between_block_columns);
			}

			.col1{
				flex-basis:100%;
				min-width:100%;
				margin-top: 0;
			}

		}
		
		.partners_grid{
			flex-wrap: wrap;
			
			.col4{
				margin-bottom: var(--gutter_mobile_between_block_columns);
			}
		}

		.partners_grid img{
			max-width: none;
			width: 100%;
		}

	}
}

// ---------------------
// SMALL MOBILE < 500
// ---------------------
@media screen and (max-width: 499px){

	.footer{

		.grid{ 
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			flex-wrap:wrap;
			padding:0;
			margin:0 auto;
			max-width: 100%;

			.col{
				margin-right: 0px;
				flex-basis:100%;
				min-width:100%;
				margin-top: var(--gutter_mobile_between_block_columns);
			}

			.col1{
				flex-basis:100%;
				min-width:100%;
				margin-top: 0;
			}

		}
	}
}
