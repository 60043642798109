

// .article_container is a section

.article{
	padding-top: var(--block-margin-top);
	padding-bottom: var(--block-margin-bottom);
}

.article_container{
	.accordion_container{
		opacity: 0;
	}
}

.article_inner{

	max-width: calc(#{$content_max_width} + var(--gutter) * 2);
	width: 100%;
	padding: 0 var(--gutter);

	margin: 0 auto;
	
	// min-height:500px; // inview fix när den inte laddat datan
	min-height: auto;

	// image to left
	.flex{
		display:flex;
		flex-direction:row;

		.image_content{
			padding-right: var(--gutter); 
			padding-left: 0;
		}
		
		.text_content{
			padding-left: var(--gutter);
			padding-right: 0;
		}
	}

	// image to right
	.flex.flexReverse{
		flex-direction:row-reverse;

		.image_content{
			padding-left: var(--gutter);
			padding-right: 0;
		}
		
		.text_content{
			padding-right: var(--gutter);
			padding-left: 0;
		}
	}

	.flex.small_image{
		.image_content{
			padding-right: var(--gutter_large); 
			padding-left: 0;
		}
		
		.text_content{
			padding-left: var(--gutter_large);
			padding-right: 0;
		}
	}

	.flex.small_image.flexReverse{
		.image_content{
			padding-left: var(--gutter_large);
			padding-right: 0;
		}
		
		.text_content{
			padding-right: var(--gutter_large);
			padding-left: 0;
		}
	}

	// widths
	.image_content{
		flex-basis:50%;
	}
	
	.text_content{
		flex-basis:50%;
	}
	
	// headline
	.article_header{
		position: relative;

		.article_header_bold, .article_header_light{
			position: relative;
		}
	}	

}

// INIT GSAP values
.article_header_bold div, .article_header_light div, .text_content_before, .article_image{
	opacity: 0;
}

.article_container{
	.header_bold, .header_light{
		color: $text_color_standard;
	}
}

// ---------------------
// MOBILE BREAK < 992
// ---------------------
@media screen and (max-width: 991px){

	.article_inner{

		.flex{
			display:flex;
			flex-direction:column !important;
			max-width: $mobile_one_col_max_width;
			align-self: center;
			margin: 0 auto !important;

			.image_content, .text_content{
				padding-right: 0 !important; 
				padding-left: 0 !important;
			}

			.text_content{
				margin-top: var(--gutter_mobile_between_block_columns) !important;
			}

		}
	}
	
}

// ---------------------
// SMALL MOBILE < 500
// ---------------------
@media screen and (max-width: 499px){

}
