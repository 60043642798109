

.recommend_container{
	
	.article_inner{
		margin-top: var(--block-margin-top);
		margin-bottom: var(--block-margin-bottom);
	}

	.recommend_header{
		@extend .headline;
		
		font-family: $header_font_family;
		color:#fff;
		// font-size: 36px;
		// line-height: 45px;
		padding: 0;
		margin:0 auto 20px auto;
		position: relative;
		text-transform: uppercase;
		font-weight:400;
		z-index: 2;
		text-align: left;
		max-width: 620px;
		opacity: 0;
	}

	.recommend_text_intro{
		max-width: 620px;
		color:#fff;
		margin-left:auto;
		margin-right:auto;
		opacity: 0;
	}

	.accordion_container_intro{
		max-width: 620px;
		color:#fff;
		margin:0 auto;
	}
	
	.accordion_container_intro p{
		color:#fff;
	}

	.accordion_container_intro{
		color:#fff;
		opacity: 0;
	}

	.accordion_button{
		color:#fff;
	}

	.recommend_acc_1, .recommend_acc_2, .recommend_acc_3{
		opacity: 0;
	}

	.grid{
		display:flex;
		flex-direction:row;
		justify-content:center;

		margin-top:30px;

		.col{
			// flex-basis:33.33333%;
			flex-basis: 425px;
			text-align:center;
			position: relative;

			.number_square{
				display:flex;
				flex-direction:column;
				justify-content:center;
				align-items:center;
				position: relative;
				height:240px;

				.recommend_text{
					max-width: 282px;
					padding:0 20px;

					font-family: $standard_font_family;
					color:#fff;
					font-weight:700;
					font-size: 24px;
					line-height: 37px;
					padding: 0;
					// margin:70px 0;
					margin:0;
					position: relative;
					z-index: 2;
					opacity: 0;
				}

				.recommend_text3{
					max-width: 330px;
				}


				.recommend_number{
					font-weight:400;
					position: absolute;

					color: #EBF6FA;
					opacity: 0.2;
					font-family: $header_font_family;
					font-size: 200px;
					line-height: 200px;
					padding: 0;
					margin:0;
					z-index: 1;
					opacity: 0;
				}
			} 

			.accordion_container{
				text-align: left;
				h3{
					@extend .headline_white;

					font-family: $standard_font_family;
					color:#fff;
					font-weight:700;
					font-size: 24px;
					line-height: 37px;
				}

				p{
					color:#fff;
				}

				.accordion_content{
					padding-left: calc(var(--gutter) / 2);
					padding-right: calc(var(--gutter) / 2);
				}
			}

			.accordion_button{
				color:#fff;
				text-align: center;
				margin: 0 auto;
				display: block;
			}
		}
	}	

	.react-parallax-background-children img{
		width:100%;
	}
}

// ---------------------
// MOBILE BREAK < 992
// ---------------------
@media screen and (max-width: 991px){

	.recommend_container{

		.recommend_header, .recommend_text_intro, .accordion_container_intro{
			max-width: $mobile_one_col_max_width;
		}

		.article_inner .grid{
			flex-direction: column;
			margin-top: 0px;

			.col{
				flex-basis: 100%;
				max-width: $mobile_one_col_max_width;
				align-self: center;
				margin: 0px 0 0 0 !important;
				padding: 0;
			}

			.col2, .col3{
				margin: 20px 0 0 0 !important;
			}
		}
	}
}

// ---------------------
// SMALL MOBILE < 500
// ---------------------
@media screen and (max-width: 499px){

}
