// _article_innovation

.article_innovation{
	
	.article_inner{
		margin-top: var(--block-margin-top);
		margin-bottom: var(--block-margin-bottom);
	}
	
	h2{
		@extend .headline_dark;
	}

	.article_inner .flex{
		display:flex;
		flex-direction:row;
		justify-content:center;
	}

	.text_content{
		padding-left:0px !important;
	}

	.article_inner .image_content{
		// flex-basis: 360px;
	}

	.image_content{
		img{
			border-radius:50%;
		}
	}
	
	.image_content .lightbox_small_image img{
		border-radius:0%;
	}

	.react-parallax-background img{
		width:100%;
	}

	.steps{
		display: flex;
		flex-direction: row;
		margin:20px 0 0px 0;

		.col_1{
			flex-basis:80px;

			img{
				width:80px;
				max-width:80px;
				height:auto;
			}
		}

		.col_2{
			padding-left: 20px;
		}

		h3{

			@extend .headline_dark;
			text-transform: uppercase;
			margin-bottom:8px;

		}
	}

	.header_bold, .header_light{
		color: $text_color_standard;
	}

}

// ---------------------
// MOBILE BREAK < 992
// ---------------------
@media screen and (max-width: 991px){

	.article_innovation{

		.article_inner .flex{
			max-width: $mobile_one_col_max_width;
			flex-direction: column;
			align-self: center;
			margin: 0 auto !important;
			padding: 0;
		}
	}
}

// ---------------------
// SMALL MOBILE < 500
// ---------------------
@media screen and (max-width: 499px){

}
