// _typography

h1, h2, h3, h4, h5, h6{
	margin:0;
	padding:0;
}


h3{
	font-size: 35px;
	line-height: 37px;
	font-weight:400;
}

.header_bold{
	font-weight: 700;
}

p{
	font-size: 17px;
	line-height: 25px;
	font-weight:400;
	color: $text_color_standard;

}