// columns_boxes

.columns_boxes{
	max-width: calc(#{$content_max_width} + var(--gutter) * 2);
	width: 100%;
	padding: 0 var(--gutter);
	margin:0 auto;

	.intro{
		max-width: 640px;
		margin:0 auto;
	}

	.article_header{
		color: $color_dark_blue;
		margin-top: var(--block-margin-top);

		.article_header_bold{}
		.article_header_light{}
	}

	.post_header{
		text-align: center !important;
		font-family: $header_font_family;
		color:$color_dark_blue;
		font-size:20px;
		line-height: 30px;
		margin-bottom: 24px;
		display:block;
	}

	.grid{
		display:flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: center;
		margin: var(--block-margin-top) calc(var(--gutter) * -1) var(--block-margin-bottom) calc(var(--gutter) * -1);

		margin-top: calc(#{var(--gutter_mobile_between_block_columns)} / 2);

	}

	.flex-third{
		flex-basis: 33.333333%;
		position: relative;

		.content{
			padding: var(--gutter);
			max-width: none;
		}

		.border{
			border: 3px dotted $color_dark_blue;
			height:100%;
			position: absolute;
			width:100%;
		}

		.box_image{
			height:63px;
			width:auto;
			display: block;
			margin: 0 auto 20px auto;
		}

		h3{
			font-family: $header_font_family;
			text-align: center;
			color:$color_dark_blue;
			font-size:36px;
			line-height: 40px;
			margin-bottom: 24px;
		}

		p, .accordion_button{
			color:$color_dark_blue;
			text-align: center;
		}

		.accordion_button{
			margin:0;
			max-width:none;
			text-align: center;
			display:block;
		}

		.accordion_content{
			color:$color_dark_blue;
			text-align: center;
			text-align: left;
		}

		p{
			text-align: left;
		}
		b{
			text-align: left;
		}
	}

	.flex-third{ // responsive
		margin-right: var(--gutter);
		margin-left: var(--gutter);
	}

	.box_text{
		font-weight:700;
	}
	// text large

/* 	.box1 .box_text{
		font-size:26px;
		line-height: 38px;
		font-style: italic;
		font-weight:700;
		// text-align: center !important;
	}

	// text medium
	.box2 .box_text{
		font-size:20px;
		line-height: 30px;
		font-style: italic;
		font-weight:700;
		// text-align: center !important;
	} */


}

// ---------------------
// HALF LARGE - less gutter
// ---------------------
@media screen and (max-width: 1200px){
	
	.columns_boxes{

		.grid{
			margin-right: calc(var(--gutter) / 2 * -1);
			margin-left: calc(var(--gutter) / 2 * -1);

			.flex-third{
				margin-right: calc(var(--gutter) / 2);
				margin-left: calc(var(--gutter) / 2);
			}	
		}
	}
}

// ---------------------
// MOBILE BREAK < 992
// ---------------------
@media screen and (max-width: 991px){

	.columns_boxes{
		.intro{
			max-width: $mobile_one_col_max_width;
		}

		.grid{
			flex-direction: column;
			margin-right: 0 !important;
			margin-left: 0 !important;

			.flex-third{
				width: 100%;
				flex-basis: 100%;
				max-width: $mobile_one_col_max_width;
				align-self: center;
				margin: 0 0 0 0 !important;
				padding: 0;
				margin-right: calc(var(--gutter) / 2);
				margin-left: calc(var(--gutter) / 2);
			}
			.flex-third{
				margin-top: var(--gutter_mobile_between_block_columns) !important;
			}

			.flex-third:first-of-type {
				margin-top: 0 !important;
			}
		}
	}
}

// ---------------------
// SMALL MOBILE < 500
// ---------------------
@media screen and (max-width: 499px){

}