// _variables

$text_color_standard: #3C3C3C;
$color_dark_blue: #16A0C4;
$color_light_blue: #EBF6FA;

$standard_font_family: 'Open Sans', sans-serif;
$header_font_family: 'Fjalla One', sans-serif;

$content_max_width: 1280px;

$mobile_one_col_max_width: 600px;

:root{
	--gutter: 25px;
	--gutter_large: 35px;
	--gutter_mobile_between_block_columns: 45px;
	--block-margin-top:45px;
	--block-margin-bottom:45px;
}


@media screen and (min-width: 0px) and (max-width: 767px){
	:root{
		--gutter: 15px;
		--gutter_large: 20px;
		--gutter_mobile_between_block_columns: 30px;
		--block-margin-top: 50px;
		--block-margin-bottom: 50px;
	}
}

@media screen and (min-width: 768px) and (max-width: 991px){
	:root{
		--gutter: 20px;
		--gutter_large: 30px;
		--gutter_mobile_between_block_columns: 40px;
		--block-margin-top: 60px;
		--block-margin-bottom: 60px;
	}
}


@media screen and (min-width: 992px){
	:root{
		--gutter: 25px;
		--gutter_large: 35px;
		--gutter_mobile_between_block_columns: 100px;
		--block-margin-top: 100px;
		--block-margin-bottom: 100px;
	}
}

.headline{
	font-family: $header_font_family;
	font-size: 32px;
	line-height: 40px;
	margin: 0;
	font-weight: 400;
	text-transform: uppercase;
}

@media screen and (max-width: 650px){
	.headline{
		font-size: 28px;
		line-height: 34px;
	}
}

.headline_white{
	font-family: $header_font_family;
	font-size: 32px;
	line-height: 40px;
	color: #fff;
	margin: 0;
}

.headline_blue{
	font-family: $header_font_family;
	font-size: 32px;
	line-height: 40px;
	color: $color_dark_blue;
	margin: 0;
}

.headline_dark{
	font-family: $header_font_family;
	font-size: 32px;
	line-height: 40px;
	color: $text_color_standard;
	margin: 0;
}

.square {
	width: 100%;
}

.square:after {
	content: "";
	display: block;
	padding-bottom: 100%;
}

.square_inner {
	position: absolute;
	width: 100%;
	height: 100%;
}

