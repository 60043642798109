
// google fonts
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

// vars
@import './components/_variables.scss';

*{
	box-sizing:border-box;
}

body {
	margin: 0;

	font-family:  $standard_font_family;
	// font-family:  "Helvetica Neue", Helvetica, Arial, sans-serif;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img{
	display:block;
	max-width:100%;
	height:auto;
}

.alignleft{
	float:left;
	margin: 0 10px 10px 0;
}

.alignright{
	float:right;
	margin: 0 0px 10px 10px;
}

.react-parallax-bgimage, .parallax_class{
    max-width: none !important;
}

// 404
.notfound{
	p{
		text-align:center;
	}
}

// scroll fix
@media screen and (min-width: 0px){
	.scroll_to_me{
		scroll-margin-top: 70px;
	}
}

@media screen and (min-width: 992px){
	.scroll_to_me{
		scroll-margin-top: 100px;
	}
}

.header_bold{
	@extend .headline;
	font-weight: 400;
}

.header_light{
	@extend .headline;
	font-weight: 300;
}

ul{
	padding-left: 18px;
	// padding-left: 0px;
	// list-style:none;
}

li::marker{
}

// parallax bg fix
.react-parallax-background-children{
	width:100%;
}

// init opacity
.header_bold, .header_light{
	opacity: 0;
}

// accordion scroll bg image
.react_parallax_transition{
	transition: all 0.3s ease-in-out;
}

// ---------------------
// quote in text 
// ---------------------

.text_quote{
	display:block;
	margin-top: 20px;
	margin-bottom: 20px;
	font-weight: 700;
	text-align: center;
}

// ---------------------
// MOBILE BREAK < 992
// ---------------------
@media screen and (max-width: 991px){

}

// ---------------------
// SMALL MOBILE < 500
// ---------------------
@media screen and (max-width: 499px){

}

// accordion_container hideReadmore

.hideReadmore{
	display:none;
}

@import './components/_accordion.scss';
@import './components/_article_bg.scss';
@import './components/_article_innovation.scss';
@import './components/_article.scss';
@import './components/_circle_chart.scss';
@import './components/_columns_boxes.scss';
@import './components/_columns_text.scss';
@import './components/_footer.scss';
@import './components/_hero.scss';
@import './components/_menu.scss';
@import './components/_quote.scss';
@import './components/_recommend.scss';
@import './components/_video.scss';
@import './components/_wheel.scss';
@import './components/_lightbox.scss';
@import './components/_ArticleTextOneCol.scss';
@import './components/_cookie.scss';
@import './components/typography.scss';

