// _wheel

.wheel{
	margin-top: 0;
	margin-bottom: var(--block-margin-bottom);
}

#svg_arrow{

}

.arrow_body{
	fill:none;
	stroke:#FFFFFF;
	stroke-width:50;
	stroke-miterlimit:10;
}

.arrow_head{
	fill:#FFFFFF;
}

#arrow, #arrow_icon, #svg_arrow, #arrow svg, .arrow_body, .arrow_head{
	pointer-events: none !important;
}


.wheel_container{
	
	.wheel_intro{
		max-width:1290px;
		width:100%; 
		margin:0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0 var(--gutter);

		.intro{
			
			h2{
				@extend .headline_blue;
				text-transform: uppercase;
				margin-bottom:8px;
				font-weight: 400;
			}

		}
	}

	.wheel_grid{
		max-width:1290px;
		width:100%; 
		margin:0 auto;
		display: flex;
		flex-direction: row;
		justify-content: center;

		// slice col
		.column_text{
			// flex-basis: 25%;
			flex-basis: 25%;

			position: relative;
			text-align: left;
			// margin-right: 20px;

			.text_item{
				position: absolute;
				top: 0;
				display: flex;
				flex-direction: row;
				opacity: 0;
			}

			.text_item h4{
				font-family: 'Fjalla One', sans-serif;
				font-size: 32px;
				line-height: 40px;
				color: #30a3c5;
				margin: 0;
			}
		}

		// design col
		.column_design{
			
			flex-basis: 25%;
			position: relative;
			z-index: 2;

			.design_graphics_text{
				display: flex;
				flex-direction: row;

			}
		}

		// wheel col
		.column_graphics{
			flex-basis: 50%;
			position: relative;
			align-self: flex-start;
			margin-top: -4% !important;
			
			.design_graphics_arrow{
				position: absolute;
				height:500px;
				width:100%;
				z-index: 1;

				#design_arrow_up{
					position: absolute;
					top: 0;
				}
			}

			#cover{
				position: absolute;
				top: 6.9%;
				top: 52px;
				top: 9%;
				z-index: 2;
			}

			// wheel graphics
			.graph_container{
				position: relative;
				z-index: 3;
				/* 
				if wheel col is 50%
				margin -9.3%

				if wheel col is 75%
				margin -14%
			
				 */
				 // margin-top: -14% !important;
				 margin-top: 7% !important; //below arrow
				 margin-bottom: -14% !important;

				 .hole, .hole2{
				 	position: absolute;
				 	background: #fff;
				 	border-radius: 50%;

				 	left: 50%;
				 	top: 50%;
				 	z-index: 2;

				 	width: 40%;
					height: 20.4%; /* 40% * 0,51 */

					margin-top: -13.2%; /* 26% / 2 (+ 3) || 3% is center at graph height*/
					margin-left: -20%;
				}

				.hole2{
					background: #e9f1f4;
					opacity: 0;
				}

				#arrow{
					position: absolute;
					left: 50%;
					top: 50%;
					z-index: 3;
					width: 51%;
					height: 51%;
					margin-top: -28.5%;
					margin-left: -25.5%;

				}

				#arrow_icon{
					width: 100%;
					height: 100%;
					opacity: 0;

					.shadow {
						-webkit-filter: drop-shadow( 9px 9px 10px rgba(0, 0, 0, .1));
						filter: drop-shadow( 9px 9px 10px rgba(0, 0, 0, .1))
					}
				}

			} // end wheel graphics

		}
	}

	.col_1{
		// flex-basis: 25%;
		flex-basis:80px;


		img{
			width:80px;
			max-width:80px;
			height:auto;
		}
	}

	.col_2{
		// flex-basis: 75%;
		padding-left: 20px;
	}


	h3{
		font-family: 'Fjalla One', sans-serif;
		font-size: 32px;
		line-height: 40px;
		font-size: 26px;
		line-height: 34px;
		color: #30a3c5;
		margin: 0;
		font-weight: 400;
		text-transform: uppercase;
	}

	svg, svg * {
		pointer-events: all;
	}

	#slice1 polygon,
	#slice2 polygon,
	#slice3 polygon,
	#slice4 polygon,
	#slice5 polygon,
	#slice6 polygon,
	#slice7 polygon{
		opacity: 0.0;
		opacity: 0.2; // update v2
	}

	#slice1 #number1 polygon,
	#slice2 #number2 polygon,
	#slice3 #number3 polygon,
	#slice4 #number4 polygon,
	#slice5 #number5 polygon,
	#slice6 #number6 polygon,
	#slice7 #number7 polygon{
		opacity: 1;
	}

	#slice7 #number7 polygon{
		opacity: 1;
	}

	#slice1,
	#slice2,
	#slice3,
	#slice4,
	#slice5,
	#slice6,
	#slice7{
		opacity: 0.0;
		cursor: pointer;
	}

	.transition_class{
		transition: all 0.3s;
	}

	#slice1:hover,
	#slice2:hover,
	#slice3:hover,
	#slice4:hover,
	#slice5:hover,
	#slice6:hover,
	#slice7:hover {
	}

} // section


// init opacity
.wheel_grid{
	opacity: 0;
}

.wheel{
	
	#design_arrow{
		opacity: 1 !important;
	}
	
	#design_arrow_up{
		opacity: 0 !important;
	}
}


// ---------------------
// Order correction
// ---------------------

.wheel_container .wheel_grid{

	.column_text{
		order:3;
		margin-left:-4%;
	}

	.column_graphics{
		order:2;
	}

	.column_design{
		order:1;
		margin-right: 4%;
	}
}

// ---------------------
// Size fix < 1350
// ---------------------

@media screen and (max-width: 1350px){

	.wheel_container .wheel_grid{
		padding:0 20px;
		overflow: hidden;

		h3{
			font-size: 22px;
			line-height: 30px;
		}

		.column_text{
			margin-left:-2%;
		}

		.column_graphics{
		}

		.column_design{
			padding-right: 0%;
			margin-right: 0%;
		}
	}
	
	.wheel_container .col_2{

	}

	.wheel_container .col_1 img{
		width:40px;
	}

}

// ---------------------
// MOBILE BREAK < 992
// ---------------------

@media screen and (max-width: 991px){
	.wheel{

		#design_arrow{
			opacity: 0 !important;
		}

		#design_arrow_up{
			opacity: 1 !important;
		}
	}

	.wheel{
		max-width: 100% !important;
		overflow: hidden !important;
		padding:0 var(--gutter);
		margin-bottom: 0;

		.wheel_container .wheel_grid{
			// flex-direction: column;
			flex-wrap: wrap;
			overflow: initial;

			h3{
				font-size: 26px;
				line-height: 34px;
			}

			.column_text{
				// margin-left: 3%;
				flex-basis: 100%;
				order: 2;
				min-height: 210px;
				max-width: #{$mobile_one_col_max_width};
				margin-top: var(--gutter);
				margin-left: 0%;
			}

			.column_graphics{
				flex-basis: 100%;
				max-width: 500px;
				order: 1;
				margin-top: -2%;
			}

			.column_design{
				flex-basis: 100%;
				justify-content: flex-end;
				display: flex;
				order: -1;

				.design_graphics_text{
					max-width: #{$mobile_one_col_max_width};
					// margin:0 auto var(--block-margin-bottom) auto;
					margin-right: 10%;
					margin-bottom: var(--gutter);
					// margin-bottom: var(--block-margin-bottom);
				}
			}
		}
	}

	.wheel_container .col_1 img{
		width:80px;
	}

}

// ---------------------
// SMALL MOBILE < 550
// ---------------------

@media screen and (max-width: 767px){

	.wheel_container .wheel_grid{
		padding: 0 0;

		.column_text{
			min-height:220px;
		}
	}
	
	.wheel .wheel_container .wheel_grid{

		.column_text{
			max-width:100%;
		}

		.column_graphics{
			
		}

		.column_design{
			max-width:100%;
		}
	}
}

@media screen and (max-width: 620px){
	
	.wheel_container .col_1 img{
		width:40px;
	}

	.wheel .wheel_container .wheel_grid h3 {
		font-size: 18px;
		line-height: 26px;
	}
	.wheel .wheel_container .wheel_grid{
		.col_1{
			flex-basis:40px;
		}
	}
	.wheel_container .wheel_grid{

		.column_text{
			min-height:270px;
		}
	}
}

@media screen and (max-width: 500px){
	.wheel .wheel_container .wheel_grid{

		p {
			font-size: 14px;
			line-height: 22px;
		}
	}
}

@media screen and (max-width: 400px){
	.wheel .wheel_container .wheel_grid .column_text{
		min-height:320px;
	}
}

