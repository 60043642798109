// _video


.video{
	background: #bedbea;
	// background: $color_light_blue;
	padding: 0 var(--gutter);
	padding-top: var(--block-margin-top);
	padding-bottom: var(--block-margin-top);


	.intro{
		max-width: #{$mobile_one_col_max_width};
		margin:0 auto calc(var(--gutter_mobile_between_block_columns) / 2) auto;
	}

	.video_container{
		position: relative;
		max-width: 940px;
		width: 100%;
		padding: 0;
		margin:0 auto;

		.video_overlay{
			position: absolute;
			top:0;
			left:0;
			right:0;
			display:flex;
			flex-direction:column;
			align-items:center;
			justify-content: center;
			z-index: 3;
			cursor:pointer;

			// background: url('../video/video_poster.jpg') no-repeat center center;
			// background-size: 100% 100%;
		}

		video {
			position: absolute;
			top:0;
			width: 100%;
			height: auto;
			display: block;
			z-index: 2;
		}
	}

	// smaller video
	.video_container{
		max-width: 940px;
	}

	// init opacity
	.header_bold, .header_light, .text{
		opacity: 0;
		color: $text_color_standard;
	}

	.play_button{
		position: absolute;
	}
}

// piss runk hora
.video_container2{
		position: relative;
		max-width: 940px;
		width: 100%;
		padding-top: 56.25%;
		margin:0 auto;

		.video_overlay{
			position: absolute;
						top:0;
			bottom:0;
			left:0;
			right:0;
		}
}

// ---------------------
// MOBILE BREAK < 992
// ---------------------
@media screen and (max-width: 991px){
	.video{
		.intro{
			margin:0 auto var(--gutter_mobile_between_block_columns) auto;
		}
	}
}

// ---------------------
// SMALL MOBILE < 500
// ---------------------
@media screen and (max-width: 499px){

}