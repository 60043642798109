// Quote

.quote_person_img{
	max-width:180px;
	display:block;
	margin:0 auto;
	border-radius:50%;
	margin-bottom: 16px;
	opacity: 0;
}

blockquote, cite{
	font-family: $header_font_family;
	color:#fff;
	font-size: 36px;
	line-height: 46px;
	// text-transform: uppercase;
	letter-spacing: 0.01em;
	padding: 0;
	margin:0 auto;
	text-align: center;
	max-width: 620px;
}

cite{
	display:block;
	font-style:normal;
	font-size: 16px;
	line-height: 20px;
	padding: 0;
	margin-top: 16px;
}

.blockquote, .cite{
	opacity: 0;
}

.quote_parallax_bg .parallax_content{
	padding-top: var(--block-margin-top);
	padding-bottom: var(--block-margin-bottom);
}

.quote_parallax_bg{

	.react-parallax-background img{
		width:100%;
	}
	
}

// ---------------------
// MOBILE BREAK < 992
// ---------------------
@media screen and (max-width: 991px){
	.quote_parallax_bg{ 
		
		.blockquote{
			padding: 0 var(--gutter);
			font-size: 36px;
			line-height: 40px;

			cite{
				font-size: 16px;
				line-height: 20px;
			}
		}
	}
}

// ---------------------
// SMALL MOBILE < 650
// ---------------------
@media screen and (max-width: 649px){
	.quote_parallax_bg{ 
		
		.blockquote{
			font-size: 30px;
			line-height: 38px;

			cite{
				font-size: 16px;
				line-height: 20px;
			}
		}
	}
}

@media screen and (max-width: 500px){
	.quote_parallax_bg{ 
		
		.blockquote{
			font-size: 24px;
			line-height: 30px;

			cite{
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
}
