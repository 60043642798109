// _columns_text

.columns_text{

	max-width: calc(#{$content_max_width} + var(--gutter) * 2);
	width: 100%;
	padding: 0 var(--gutter);
	margin:0 auto;

	margin-top: var(--block-margin-top);
	margin-bottom: var(--block-margin-bottom);
	
	display:flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: center;
	
	.flex-half, .content_single_col{

		flex-basis: 50%;
		position: relative;

		h2{
			font-family: $header_font_family;
			color:$color_dark_blue;
			font-size:36px;
			line-height: 40px;
			margin-bottom: 24px;
			font-weight: 400;
		}

		p{
			font-family: $standard_font_family;
			color:$text_color_standard;
			margin: 0px auto 1em auto;
		}

		p.text_subheader{
			font-family: $header_font_family;
			font-size:20px;
			line-height: 26px;
			margin-bottom: 24px;
		}

		.text_header2{
			opacity: 0 !important;
		}

	}

	.flex-half:nth-child(1){ // responsive
		margin-right: var(--gutter);
	}

	.flex-half:nth-child(2){ // responsive
		margin-left: var(--gutter);
	}

	// init opacity
	.content, .lighter_button{
		opacity: 0;
	}

	.lighter_button{
		background: $color_dark_blue;
		display: inline-block;
		margin-top: 25px;

		a, a:visited, a:active, a:hover{
			padding: 0 40px;
			line-height: 50px;
			font-size: 16px;
			color: #fff !important;
			font-family: $header_font_family;
			font-weight: 400;
			text-decoration: none;
			display: inline-block;
			text-transform: uppercase;
			transition: all 0.3s;
		}
	}

	.lighter_button a:hover{
		transform: scale(1.1) !important;
		transition: all 0.3s;
	}

	.content_single_col{
		max-width: $mobile_one_col_max_width;
	}


	// init opacity
	.content_single_col{
		.text_header1, .text_subheader1, .text_text1, .accordion_container, .lighter_button{
			opacity: 0;
		}
	}
	
}

// ---------------------
// MOBILE BREAK < 992
// ---------------------
@media screen and (max-width: 991px){
	.columns_text{
		flex-direction: column;

		.flex-half{
			flex-basis: 100%;
			max-width: $mobile_one_col_max_width;
			align-self: center;
			margin: 0 0 0 0 !important;
			padding: 0;
		}

		.flex-half:nth-child(2){
			margin-top: var(--gutter_mobile_between_block_columns) !important;
		}

		.text_header2{
			display:none;
		}

		.flex-half p:last-of-type{
			margin-bottom:0;
		}

	}
}

// ---------------------
// SMALL MOBILE < 500
// ---------------------
@media screen and (max-width: 499px){

}
