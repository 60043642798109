// _article_bg

.article_bg_container{
	
	.article_inner{
		margin-top: var(--block-margin-top);
		margin-bottom: var(--block-margin-bottom);
	}

	.article_inner .flex{
		display:flex;
		flex-direction:row;
		justify-content:center;
	}
	
	.flex.small_image{
		.article_inner .image_content{
			flex-basis: 360px;
		}

		.image_content{
			img{
				border-radius:50%;
			}
		}
	}

	.header_light{
		// font-weight:700;
	}


	.react-parallax-background img{
		width:100%;
	}

	.header_bold, .header_light{
		color: $text_color_standard;
	}

	// init opacity
	.accordion_container{
		opacity: 0;
	}
}

// ---------------------
// MOBILE BREAK < 992
// ---------------------
@media screen and (max-width: 991px){
	.article_bg_container .article_inner{

		.flex{
			flex-direction: column;
			margin-top: var(--block-margin-top);
			margin-bottom: var(--block-margin-bottom);

			.image_content, .text_content{
				flex-basis: 100%;
				max-width: $mobile_one_col_max_width;
				align-self: center;
				margin: 0 0 0 0 !important;
				padding: 0 !important;
			}

			.text_content{
				margin-top: var(--gutter_mobile_between_block_columns) !important;
			}

		}

		.flex.small_image{
			.image_content img{
				max-width:300px !important;
			}
		}
	}
}

// ---------------------
// SMALL MOBILE < 500
// ---------------------
@media screen and (max-width: 499px){
	
	.article_bg_container .article_inner .flex.small_image .image_content img{
		max-width:240px !important;
	}

}

// ---------------------
// COMPONENT SPECIFIC
// ---------------------

.article_intro{
	.article_header_bold{
		font-size: 18px;
		text-transform: none;
		margin-bottom:5px !important;
		display: inline-block;
	}

/* 	.article_header_bold{
		display:none !important;
	}

	.article_header br{
		display:none !important;
	} */
}