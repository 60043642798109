// Lightbox

.hide_lightbox{
	display:none !important;
}

.show_lightbox{
	display:block;
}

.lightbox_section{
	position: fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	z-index: 99998;
	width:100%;
	height:100%;
	display:flex;
	flex-direction:column;
	align-items: center;
	justify-content: center;
}

.lightbox_close_container{
	max-width: 1640px;
	width: 100%;
	position: relative;
	height: 40px;
}

.lightbox_close{
	position: absolute;
	top: -50px;
	right: 0;
	z-index: 99999;
	margin: 4px 0% 0 0;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	width:120px;
	color:#fff;
	cursor:pointer;

	.close_icon{
		height:40px;
		width:40px;
		background: url('../img/structure/close.svg') no-repeat center center;
		background-size: 100% 100%;
		top: -20px;
		margin-left:8px;
	}
}

.content{
	position: relative;
	max-width: calc(100% - 60px);
	max-height: calc(100% - 100px);
}

.lightbox_image{
	width: auto;
	height: auto;
	z-index: 99999;
	max-width: 100%;
	max-height: 100%;
	position: relative;
}

.lightbox_image img{
	max-width: 100%;
	max-height: 100%;
}

.lightbox_cover{
	position: fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	z-index: 99990;
	width:100%;
	height:100%;
	background: rgba($color_dark_blue, 0.95);
}

.lightbox_small_image{
	cursor:pointer;
}

.lightbox_description{
	font-family:  $standard_font_family;
	display:inline-block;
	font-size:14px;
	line-height:20px;
	position: absolute;
	margin-top:5px;
}

.lightbox_description:after{
	content: '';
	width:16px;
	height:16px;
	background: url('../img/structure/zoom.svg') no-repeat center center;
	position: absolute;
	margin: 2px 0 0 5px;
}

@media screen and (max-width: 991px){
	.lightbox_description{
		position: relative;
	}
}


@media screen and (max-height: 900px){
	.lightbox_close{
		top: -40px;

		.close_icon{
			height:30px;
			width:30px;
		}
	}
}
