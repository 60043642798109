// _circle_chart

.section_circle_graph{

	.fullwidth_container{
		width: 100%;
		background: #16a0c4;
		padding: 0 20px;
	}

	.graph_columns{
		max-width: calc(#{$content_max_width} + var(--gutter) * 2);
		width: 100%;
		padding: var(--block-margin-top) var(--gutter) calc(var(--block-margin-bottom) * 0.3333) var(--gutter);
		margin:0 auto;

		display:flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: center;
	}

	.disclamber{
		padding-bottom: calc(var(--block-margin-bottom) * 0.75);
		color:#fff;
		text-align: center;
		font-size: 12px;
		line-height: 16px;
	}

	.circle_graph{
		max-width: 282px; // 282
		width: 100%;
		margin: 0 auto;
		position: relative;
	}

	.circle_graph:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}

	.graph_content {
		position: absolute;
		width: 100%;
		height: 100%;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}


	.svg_circle_graph{
		position: absolute;
		width: 100%;
		height: 100%;
		fill: none;
		stroke-width: 480px;	
	}

	.graph_info{
		position: relative;
		text-align: center;
		z-index: 2;
		width: 100%;
	}
	.score_container, .text{
		color: #fff;
		text-align: center;
	}

	.score_container{
		font-size: 64px;
		line-height: 80px;
		display: inline-block;
		text-align: center;
		margin: -70px auto 0 auto;
		font-family: $header_font_family;
	}
	.score{
		// font-size: 100px;
		// line-height: 102px;
		display: inline;
	}

	.text{
		padding: 0px 60px 0;
		width: 100%;
		position: absolute;
		font-family: $header_font_family;
		font-size: 16px;
		line-height: 22px;
	}
	.circle_graph1 .text{
		padding: 0px 30px 0;
	}

}

.pie_chart{
	margin: 0 auto;
	transform: rotate(-90deg);
	background: #ddd;
	background: transparent;
	border-radius: 50%;
	display: block;
	position: absolute;
}

.pie_piece{
	fill: transparent;
	stroke: rgba(235, 246, 250, 0.26);
	stroke-width: 140;
	stroke-dasharray: 0 440;
	transition: stroke-dasharray 2s ease;
}

// ---------------------
// MOBILE BREAK < 1100
// ---------------------
@media screen and (max-width: 1100px){

	.section_circle_graph{

		.graph_columns{
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: center;

			.circle_graph {
				max-width: 282px;
				width: 100%;
				margin: 0 auto;
				position: relative;
				margin-top: calc(var(--gutter_mobile_between_block_columns) / 2);
			}
			.circle_graph:first-of-type {
				margin-top: 0 !important;
			}
		}
	}

}

@media screen and (max-width: 991px){

	.section_circle_graph{
		.circle_graph {
			margin-top: var(--gutter_mobile_between_block_columns) !important;
		}
		.circle_graph:first-of-type {
			margin-top: 0 !important;
		}
	}

}

// ---------------------
// SMALL MOBILE < 500
// ---------------------
@media screen and (max-width: 499px){

}
