// _accordion_content

.accordion_content{
	overflow: hidden;
	height:0px;
}

.accordion_button{
	cursor: pointer;
}


.accordion_content:first-of-type p{
	margin-top:0px;
}

.accordion_button{
	display: inline-block;
	color: inherit;
	white-space: nowrap;
	position: relative;
	font-style: normal;
	color: $text_color_standard;

	&::after {
		content: '→';
		position: relative;
		display: inline-block;
		transform: translateX(0);
		transition: transform 0.3s;
		padding: 0 0 0 5px;
	}
}

.accordion_button:hover{
	&::after {
		transform: translateX(8px);
	}
}