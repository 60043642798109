// ArticleTextOneCol


.ArticleTextOneCol{
    padding-top: 0;
    padding-bottom: 0;
    position: relative;

	.article_inner {
		max-width: calc(1280px + var(--gutter) * 2);
		width: 100%;
		padding: 0 var(--gutter);
		margin: 0 auto;
		min-height: auto;

		max-width: #{$mobile_one_col_max_width};
		margin: var(--block-margin-top) auto 0 auto;
		padding-bottom: var(--block-margin-bottom);
		
		// padding-bottom: calc(var(--gutter_mobile_between_block_columns) / 2);
	}



	.react-parallax-background img{
		width:100%;
	}

	.article_inner > div:first-of-type{
		position: relative;
		z-index: 2;
	}

	.gradient{
		position: absolute;
		width: 100%;
		height: 200px;
		bottom:0;
		z-index: 1;
		
		background: rgb(255,255,255);
		background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 6%, rgba(255,255,255,0.3) 55%, rgba(255,255,255,0) 100%);
	}

	.accordion_container{
		opacity: 0;
	}
}
